// Navbar.js

import React from 'react';
import { AppBar, Toolbar, Typography, Container } from '@mui/material';
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/logo1.png"

const Navbar = () => {
  return (
    <AppBar position="fixed" sx={{ backgroundColor: '#212121' }}>
    <Container style={{padding:10}}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontFamily: 'Roboto, sans-serif'  }}>
          <img src={logo} alt="Logo" style={{ width: 50, height: 50 }} />
        </Typography>
        <nav>

        </nav>
      </Toolbar>
    </Container>
  </AppBar>
  );
};

export default Navbar;

