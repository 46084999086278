import React, { useEffect } from 'react';

import backgroundImage from "../../assets/backgroundImage.png";
import backgroundphone from "../../assets/backgroundphone.png";

import { useMediaQuery,Container, Box ,Typography,Grid } from '@mui/material';

import DownloadIosButton from "../../components/downloadIosButton";
import DownloadAndroidButton from "../../components/downloadAndroidButton";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Navbar from '../main/mainNavbar/Navbar';


const RedirectComponent = () => {
    const isDesktop = useMediaQuery('(min-width:600px)');
    const mybackgroundImage=isDesktop?backgroundImage : backgroundphone;
    const redirectToStore = () => {
        const iosStore ="https://apps.apple.com/us/app/gogainz-athlete-healthy-food/id6465424687";
        const androidStore="https://play.google.com/store/apps/details?id=com.gogainz.gogainz"
        const websiteUrl = 'https://gogainz.ma';
      
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      
      if (isMobile) {
        const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
        const storeUrl = isIOS ? iosStore : androidStore;
        window.location.replace(storeUrl);
      } else {

        window.location.replace(websiteUrl);
      }
    };

  useEffect(() => {
   

    redirectToStore();
  }, []);

  return (
    <>
      <Navbar/>

      <Box
      sx={{
        // Replace with the path to your background image
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: 'calc(100vh - 80px)', // Adjust the height as needed
        marginTop: '80px', 
        backgroundImage:  `url(${mybackgroundImage})`,// Adjust based on the height of your navbar
   
      }}

    >
      <Container>
        
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <Grid container>
          <Grid item md={7} sm={12}  justifyContent="center">

          <Typography variant="body1" sx={{color: 'white',fontWeight:800 , fontFamily: 'Roboto, sans-serif',fontSize:30}}>
          GoGainz - Nutrition for Ideal Physique
          </Typography>
          <br/>
          <br/>
          <Typography  sx={{color: 'white',fontWeight:800 , fontFamily: 'Roboto, sans-serif' ,fontSize:20}}>
          Fuel peak performance with Gogainz! Athlete meals, precise tracking, personalized plans, and fast delivery. All of that to achieve your ultimate body shape. Download now!   </Typography>
          <br/>
      
          <Grid container spacing={5} justifyContent={"center"}>
            <Grid item md={6} sm={12}  >
            <DownloadIosButton/>
            </Grid>
            <Grid item md={6} sm={12}>
            <DownloadAndroidButton/>
              
              </Grid>

          </Grid>
          </Grid>
         <br/>
         <br/>

        </Grid>
     
 
   
      </Container>
    </Box>

    </>
  
  );
};

export default RedirectComponent;
