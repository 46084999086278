import { Route, Routes, Navigate } from "react-router-dom";






//Pages

import HomePage from "../pages/main";
import PrivacyPage from "../pages/privacy"
import DeleteAccountPage from "../pages/deleteAccount"
import DownloadPage from "../pages/download";

const MainRoutes = ()=>{

  return (
    <Routes>
       <Route path="/privacy" exact element={<PrivacyPage/>} />
       <Route path="/deleteMyAccount" exact element={<DeleteAccountPage/>} />
       <Route path="/download" exact element={<DownloadPage/>} />
       <Route path="*" exact element={<HomePage/> } />
   
   
     </Routes>)

};
//  <Route element={<Layout/>}>
export default MainRoutes;
