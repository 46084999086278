import React from 'react';

import backgroundImage from "../../assets/backgroundImage.png";
import backgroundphone from "../../assets/backgroundphone.png";

import { useMediaQuery,Container, Box ,Typography,Grid } from '@mui/material';

import DownloadIosButton from "../../components/downloadIosButton";
import DownloadAndroidButton from "../../components/downloadAndroidButton";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Navbar from './privacyNavbar';


const Privacy= () => {
    const textStyle = {
        color: 'white',
        fontFamily: 'Roboto, sans-serif',
        fontSize: '1.1rem', // Adjust the font size as needed
        lineHeight: '1.6', // Adjust the line height as needed
      };


    return (
        <>
        <Navbar/>
        <Box
      sx={{
        // Replace with the path to your background image
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: 'calc(100vh - 80px)', // Adjust the height as needed
        marginTop: '130px', 
     // Adjust based on the height of your navbar
   
      }}

    >
        <Container>
        <h1 style={textStyle}>Privacy Policy for Gogainz Inc</h1>
      <p style={textStyle}>Last Updated: [December 14, 2023]</p>

      <p style={textStyle}>
        Welcome to Gogainz, a service provided by Gogainz Inc. We are committed
        to protecting your privacy and ensuring that your personal information
        is handled responsibly and securely. This Privacy Policy explains how we
        collect, use, and safeguard your data when you use our mobile
        application.
      </p>

      <h2 style={textStyle}>Information We Collect</h2>

      <ol >
        <li style={textStyle}>
          <strong >Email:</strong> We collect your email address to facilitate
          communication and account management.
        </li>
        <li style={textStyle}>
          <strong>Location:</strong> We collect your location data to provide
          precise delivery services based on your location.
        </li>
        <li style={textStyle}> 
          <strong>User ID:</strong> Upon connecting to our app, each user is
          assigned a unique identifier for account identification.
        </li>
        <li style={textStyle}>
          <strong>Phone Number:</strong> When you place an order, we collect
          your phone number for communication and order delivery purposes.
        </li>
        <li style={textStyle}>
          <strong>Crash Data:</strong> We collect crash data for the purpose of
          improving the user experience and application stability.
        </li>
      </ol>

      <h2 style={textStyle}>How We Use Your Information</h2>

      <p style={textStyle}>
        The collected data is used solely for the functionality of the Gogainz
        app, including order processing, delivery, and communication.
      </p>

      <h2 style={textStyle}>Data Security and Storage</h2>

      <p style={textStyle}>
        Your data is securely stored on our backend application hosted on AWS
        (Amazon Web Services). We implement industry-standard security measures
        to protect your information.
      </p>

      <h2 style={textStyle}>No Sharing with Third Parties</h2>

      <p style={textStyle}>
        We do not share your collected data with any third-party APIs. Your
        information is used exclusively for app functionality.
      </p>

      <h2 style={textStyle}>User Rights</h2>

      <p style={textStyle}>
        You have the right to access, correct, or delete your personal
        information. Contact us at{' '}
        <a href="mailto:gogainz.ma@gmail.com" style={{color:"#E19F17"}}>gogainz.ma@gmail.com</a> for
        assistance.
      </p>

      <h2 style={textStyle}>Children's Privacy</h2>

      <p style={textStyle}>
        Gogainz is not intended for users under the age of 13. We do not
        knowingly collect personal information from children.
      </p>

      <h2 style={textStyle}>Changes to Privacy Policy</h2>

      <p style={textStyle}>
        Our Privacy Policy is subject to change. Any updates will be posted on
        our website.
      </p>

      <h2 style={textStyle}>Contact Information</h2>

      <p style={textStyle}>
        <strong>Business Name:</strong> Gogainz Inc
      </p>
      <p style={textStyle}>
        <strong>Email:</strong>{' '}
        <a href="mailto:gogainz.ma@gmail.com" style={{color:"#E19F17"}}>gogainz.ma@gmail.com</a>
      </p>

      <p style={textStyle}>
        For any privacy-related concerns, please contact us at the provided
        email address.
      </p>
        </Container>
        </Box>

        </>
    )



}
export default Privacy;