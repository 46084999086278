import React from 'react';
import "./downloadios.css";


import { Button, Box, Typography } from '@mui/material';
import AppleIcon from '@mui/icons-material/Apple';

const App = () => {

  const iosStore ="https://apps.apple.com/us/app/gogainz-athlete-healthy-food/id6465424687";
    

    return (
        <Button
        variant="contained"
        style={{backgroundColor:"#000000",padding:15,width: 250}}

        onClick={() =>{
          window.location.href=iosStore;
        }}
        // color="black" // You may need to use a specific color that matches your design
        // startIcon={<AppleIcon />}
        // sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
      >
 
          {/* iOS icon */}
          <AppleIcon sx={{ fontSize: 30, marginRight: 1 }} />
          {/* Download on the */}
          <Typography variant="body1" sx={{color: 'white',fontWeight:800 , fontFamily: 'Roboto, sans-serif',fontSize:15 }}>
            Download on the   App Store
          </Typography>

      </Button>
    
    );
  };
  
  export default App;