import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";

// import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";

// import { saveLogoutInLocalStorage } from "../helpers/constants";
  
const MyRoutes= () => {

  return (
    <Router>
 <MainRoutes/> 

    
    </Router>
  );
}
export default MyRoutes;