


import React from 'react';

import backgroundImage from "../../assets/backgroundImage.png";
import backgroundphone from "../../assets/backgroundphone.png";

import { useMediaQuery,Container, Box ,Typography,Grid } from '@mui/material';

import DownloadIosButton from "../../components/downloadIosButton";
import DownloadAndroidButton from "../../components/downloadAndroidButton";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Navbar from './mainNavbar/Navbar';
const theme = createTheme();

const Main = () => {

  const isDesktop = useMediaQuery('(min-width:600px)');
  const mybackgroundImage=isDesktop?backgroundImage : backgroundphone;
  return (
    <>
      <Navbar/>

      <Box
      sx={{
        // Replace with the path to your background image
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: 'calc(100vh - 80px)', // Adjust the height as needed
        marginTop: '80px', 
        backgroundImage:  `url(${mybackgroundImage})`,// Adjust based on the height of your navbar
   
      }}

    >
      <Container>
        
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <Grid container>
          <Grid item md={7} sm={12}  justifyContent="center">

          <Typography variant="body1" sx={{color: 'white',fontWeight:800 , fontFamily: 'Roboto, sans-serif',fontSize:30}}>
          GoGainz - Nutrition for Ideal Physique
          </Typography>
          <br/>
          <br/>
          <Typography  sx={{color: 'white',fontWeight:800 , fontFamily: 'Roboto, sans-serif' ,fontSize:20}}>
          Fuel peak performance with Gogainz! Athlete meals, precise tracking, personalized plans, and fast delivery. All of that to achieve your ultimate body shape. Download now!   </Typography>
          <br/>
      
          <Grid container spacing={5} justifyContent={"center"}>
            <Grid item md={6} sm={12}  >
            <DownloadIosButton/>
            </Grid>
            <Grid item md={6} sm={12}>
            <DownloadAndroidButton/>
              
              </Grid>

          </Grid>
          </Grid>
         <br/>
         <br/>

        </Grid>
     
 
   
      </Container>
    </Box>

    </>
  
  );
};

export default Main;
