import React from 'react';

import step1 from "../../assets/step1.png";
import step2 from "../../assets/step2.png";
import step3 from "../../assets/step3.png";
import step4 from "../../assets/step4.png";



import { useMediaQuery,Container, Box ,Typography,Grid } from '@mui/material';

import DownloadIosButton from "../../components/downloadIosButton";
import DownloadAndroidButton from "../../components/downloadAndroidButton";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Navbar from './deleteNavbar';


const DeleteAccountPage= () => {
    const textStyle = {
        color: 'white',
        fontFamily: 'Roboto, sans-serif',
        fontSize: '1.1rem', // Adjust the font size as needed
        lineHeight: '1.6', // Adjust the line height as needed
      };


    return (
        <>
        <Navbar/>
        <Box
      sx={{
        // Replace with the path to your background image
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: 'calc(100vh - 80px)', // Adjust the height as needed
        marginTop: '130px', 
     // Adjust based on the height of your navbar
   
      }}

    >
        <Container>
        <h1 style={textStyle}>Important Notice Regarding Account Deletion</h1>
      <p style={textStyle}>Dear Customer,</p>

      <p style={textStyle}>
      We understand that circumstances may lead you to consider deleting your account with us, and we want to ensure that you are fully informed about the consequences of this action.
      </p>
       <p style={textStyle}>
       Please be aware that deleting your account will result in the permanent removal of all associated data. This includes your personal information, account settings, order history, and any other data linked to your account. Once the deletion process is initiated, we will not be able to recover this information, and it will be permanently lost.
       </p>
       <p style={textStyle}>
       Before proceeding, we recommend carefully reviewing your decision and considering any potential impact on your access to our services. If you are certain about deleting your account and understand the implications of data loss, please follow the steps outlined below:
       </p>

   
       <Grid container>
        <Grid item xs={6} md={3}>
          <p style={textStyle}>1-</p>
          <br/>
          <img src={step1} alt="step 1"  style={{width:"80%",display:"flex",justifyContent:"center"}} />

        </Grid>
        <Grid item xs={6} md={3}>
          <p style={textStyle}>2-</p>
          <br/>
          <img src={step2} alt="step 2" style={{width:"80%",display:"flex",justifyContent:"center"}}  />

        </Grid>
        <Grid item xs={6} md={3}>
          <p style={textStyle}>3-</p>
          <br/>
          <img src={step3} alt="step 3" style={{width:"80%",display:"flex",justifyContent:"center"}}   />

        </Grid>
        <Grid item xs={6} md={3}>
          <p style={textStyle}>4-</p>
          <br/>
          <img src={step4} alt="step 4"  style={{width:"80%",display:"flex",justifyContent:"center"}}  />

        </Grid>

       </Grid>
       <br/>
       <p style={textStyle}>
When you click the "DELETE ACCOUNT" button, a crucial step in ensuring the security and accuracy of the account deletion process is the verification of your identity. To initiate the deletion, you will be prompted to reconnect with your account and undergo a secure authentication process. This step is designed to confirm that it is indeed you who is initiating the account deletion. Once your authentication is successfully verified, the system will proceed with permanently deleting all associated data linked to your account. 
       </p>
       <br/>
       <br/>
       <br/>

    
        </Container>
        </Box>

        </>
    )



}
export default DeleteAccountPage;