import logo from './logo.svg';
import './App.css';

import React from 'react';
import Navbar from './pages/main/mainNavbar/Navbar';
import backgroundImage from "./assets/backgroundImage.png";
import backgroundphone from "./assets/backgroundphone.png";
import { useMediaQuery,Container, Box ,Typography,Grid } from '@mui/material';

import DownloadIosButton from "./components/downloadIosButton";
import DownloadAndroidButton from "./components/downloadAndroidButton";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Routes from "./routes"

const theme = createTheme();

const App = () => {

  const isDesktop = useMediaQuery('(min-width:600px)');
  const mybackgroundImage=isDesktop?backgroundImage : backgroundphone;
  return (
    <Routes/>
  
  );
};

export default App;
