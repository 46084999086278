import React from 'react';
import "./downloadandroid.css";


import { Button, Box, Typography } from '@mui/material';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';

const App = () => {
  const androidStore="https://play.google.com/store/apps/details?id=com.gogainz.gogainz"
  


    return (
        <Button
        variant="contained"
        style={{backgroundColor:"#000000",padding:15 ,width: 250}}
        onClick={() =>{
          window.location.href=androidStore;
        }}
        // color="black" // You may need to use a specific color that matches your design
        // startIcon={<AppleIcon />}
        // sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
      >
 
          {/* iOS icon */}
          <AndroidIcon sx={{ fontSize: 30, marginRight: 1 ,color: "#32DE84"}} />
          {/* Download on the */}
          <Typography variant="body1" sx={{ color: 'white',fontWeight:800 , fontFamily: 'Roboto, sans-serif' ,fontSize:15 }}>
            Download on Google Play
          </Typography>

      </Button>
    
    );
  };
  
  export default App;